.App {
  font-family: sans-serif;
  text-align: center;
}

.card {
  /* width: 18.65rem; */
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

h2 {
  margin: 5% 0%;
}

#klogo {
  height: 100px;
  width: 100px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  background-color: #ffdc18;
  z-index: 1;
}

.cImg {
  height: 800px;
  object-fit: cover;
}

.card-header {
  background-color: #ffdc18;
  color: black;
}

.map {
  border-radius: 1%;
}

.klogo1 {
  position: absolute;
  z-index: 1;
  left: 50px;
}

.card {
  border-color: aliceblue;
}

.card-text {
  font-family: "Inter", sans-serif;
}

.card-title {
  font-family: "Montserrat", sans-serif;
}

.card-header {
  font-family: "Montserrat", sans-serif;
}

.core-value-text {
  font-family: "Inter", sans-serif;
}

.aboutImg {
  height: 100px;
  width: 50%;
  object-fit: cover;
}
